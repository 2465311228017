<template>
  <div>
    <!-- <this-header /> -->
    <div class="anan-set-nav-conttainer mt-150">
      <div class="row">
        <div class="col-lg-2 col-md-2 col-sm-12">
          <side-bar class="d-none d-md-block" />
        </div>

        <div class="col-md-10 col-lg-10 col-sm-12 ">
          <h3 class="font-weight-bold mb-1 ">
            {{ $t('key-158') }} Line
          </h3>

          <div class="-line-notify">
            <div class="-line-bg" />
            <div class="-line-in">
              <img
                :src="`${line_picture ? line_picture : '/icon/line.webp'}`"
                alt="line-icon"
              >
              <p>
                {{ line_name ? $t('greeting') + line_name : $t('key-159')+' Line' }}
              </p>

              <button
                v-if="!line_name"
                @click="GetLink"
              >
                <img
                  src="/icon/line-btn.png"
                  alt=""
                > {{ $t('key-160') }}
              </button>
            </div>
          </div>

          <!-- <div class="-line-notify">
            <div class="-line-bg" />
            <div class="-line-in">
              <img
                :src="line_picture"
                alt="line-icon"
              >
              <p>
                {{ line_name }}
              </p>

              <button
                @click="GetLink"
              >
                <img
                  src="/icon/line-btn.png"
                  alt=""
                > เชื่อมต่อไลน์
              </button>
            </div>
          </div> -->

        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import SideBar from './component/SideBar.vue'

export default {
  name: 'LineNotify',
  components: {
    SideBar,
    // ThisHeader,
  },
  data() {
    return {
      code: '',
      state: '',
      line_name: null,
      line_picture: null,
    }
  },
  computed: {

  },
  mounted() {
    this.code = this.$route.query.code
    this.state = this.$route.query.state
    if (this.code && this.state) {
      this.GetToken()
    } else {
      this.CheckConnect()
    }
  },
  methods: {
    async CheckConnect() {
      try {
        const { data: res } = await this.$http.get('/line/connect')
        console.log(res)
        this.line_name = res.name
        this.line_picture = res.picture
      } catch (e) {
        console.log(e)
      }
    },
    async GetLink() {
      try {
        const { data: res } = await this.$http.get('/line/login')
        console.log(res)
        window.location.href = res
      } catch (e) {
        console.log(e)
      }
    },
    async GetToken() {
      try {
        const params = {
          code: this.code,
          state: this.state,
        }
        const { data: res } = await this.$http.get('/line/token', { params })
        this.line_name = res.name
        this.line_picture = res.picture
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
